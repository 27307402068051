<template>
  <main>
    <!-- Modal -->
    <div id="exampleModal" class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">BARANG</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <v-text-field
              v-model="search"
              class="pt-5"
              label="Search"
              dense
              outlined
              @keyup="doSearch()"
            ></v-text-field>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-uppercase">NO</th>
                    <th class="text-center text-uppercase">Kode Barang</th>
                    <th class="text-center text-uppercase">Nama Barang</th>
                    <th class="text-center text-uppercase">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in displayData()" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td class="text-center">
                      {{ data.kode }}
                    </td>
                    <td class="text-center">
                      {{ data.nama_barang }}
                    </td>
                    <td class="text-center">
                      <v-btn
                        data-bs-dismiss="modal"
                        color="primary"
                        class="me-3 mt-4"
                        @click="CreateStockOpnameModal(data.kode)"
                      >
                        <v-icon color="#ECEFF1">
                          {{ icons.mdiCheckBold }}
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="6">
                      <center>
                        <div class="v-data-footer">
                          <span class="v-data-footer__icons-before" bis_skin_checked="1">
                            <button
                              type="button"
                              :class="[
                                'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                page == 1 ? 'v-btn--disabled' : '',
                              ]"
                              aria-label="Previous page"
                              @click="page--"
                            >
                              <span class="v-btn__content"
                                ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                  ><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    role="img"
                                    aria-hidden="true"
                                    class="v-icon__svg"
                                  >
                                    <path
                                      d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                    ></path></svg></span
                              ></span>
                            </button>
                          </span>
                          <span
                            v-for="pageNumber in pages.slice(page - 1, page + 5)"
                            :key="pageNumber"
                            :class="['v-data-footer', pageNumber == page ? 'active' : '']"
                            @click="page = pageNumber"
                          >
                            <button
                              type="button"
                              class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                              aria-label="Next page"
                            >
                              {{ pageNumber }}
                            </button>
                          </span>
                          <span class="v-data-footer__icons-after" bis_skin_checked="1">
                            <button
                              type="button"
                              :class="[
                                'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                page < pages.length ? '' : 'v-btn--disabled',
                              ]"
                              aria-label="Next page"
                              @click="page++"
                            >
                              <span class="v-btn__content"
                                ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                  ><svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    role="img"
                                    aria-hidden="true"
                                    class="v-icon__svg"
                                  >
                                    <path
                                      d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                    ></path></svg></span
                              ></span>
                            </button>
                          </span>
                        </div>
                      </center>
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </div>
        </div>
      </div>
    </div>
    <!-- end Modal -->

    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat>
      <v-row>
        <v-col cols="6" sm="6" md="2">
          <v-radio-group style="padding-left: 15px" v-model="penjualan.anggota" column>
            <v-radio
              v-on:click="isHiddenAnggotaKoperasi = !isHiddenAnggotaKoperasi"
              label="Umum"
              color="primary"
              value="U"
              checked
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="6" sm="6" md="2">
          <v-radio-group v-model="penjualan.anggota" column>
            <v-radio
              v-on:click="isHiddenAnggotaKoperasi = !isHiddenAnggotaKoperasi"
              label="Anggota Koperasi"
              color="error"
              value="A"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col style="padding-top: 25px" cols="4" md="4">
          <v-combobox
            v-if="isHiddenAnggotaKoperasi"
            label="Nama Anggota Koperasi"
            v-model="penjualan.customer"
            id="customer"
            :items="customers"
            dense
            outlined
            clearable
          ></v-combobox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" md="5">
          <v-text-field style="padding: 15px" v-model="invoices" label="Invoice" required></v-text-field>
        </v-col>
        <v-col cols="4" sm="6" md="5">
          <v-text-field
            @keyup.enter="Createpenjualantemp()"
            v-model="penjualan.kode"
            label="Kode Barang / Scan Barcode"
            autofocus
          ></v-text-field>
        </v-col>
        <v-col md="1">
          <v-row align="center" justify="center">
            <v-btn color="primary" class="me-2 mt-4">
              <a data-bs-toggle="modal" data-bs-target="#exampleModal">
                <v-icon color="#ECEFF1">
                  {{ icons.mdiArchiveSearchOutline }}
                </v-icon>
              </a>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card outlined tile>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th style="width: 5px" class="text-uppercase">NO</th>
              <th class="text-center text-uppercase">KODE</th>
              <th class="text-center text-uppercase">NAMA BARANG</th>
              <th style="width: 80px" class="text-center text-uppercase">JUMLAH</th>
              <th style="width: 130px" class="text-center text-uppercase">HARGA</th>
              <th class="text-center text-uppercase">SUB TOTAL</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in transaksipenjualan" :key="index">
              <td>{{ 1 + index }}</td>
              <td class="text-center">{{ data.kode }}</td>
              <td class="text-center">{{ data.nama_barang }}</td>
              <td class="text-center" style="padding-top: 10px">
                <v-text-field
                  v-model="data.jumlah"
                  @keyup.enter.prevent="
                    isNumber($event)
                    UpdatePenjualan(data.id_transaksi_penjualantemp, index)
                  "
                  solo
                ></v-text-field>
              </td>
              <td class="text-center" style="padding-top: 10px">
                {{ data.harga_jual_ppn | rupiah }}
              </td>
              <td class="text-center" style="padding-top: 10px; font-weight: bold">
                {{ data.sub_total_penjualan | rupiah }}
              </td>

              <td class="text-center">
                <v-btn
                  @click="deletePost(data.id_transaksi_penjualantemp, index)"
                  color="error"
                  class="mt-4"
                  type="button"
                >
                  <v-icon color="#ECEFF1">
                    {{ icons.mdiDelete }}
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>&nbsp;</td>

              <td class="text-center">&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td class="text-center">&nbsp;</td>
            </tr>
            <tr>
              <td>&nbsp;</td>

              <td class="text-center">&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td style="font-size: 20px; font-weight: bold">Total</td>
              <td style="font-size: 20px; font-weight: bold" colspan="2" class="text-right">
                {{ totaltransaksis | rupiah }}
                <input type="hidden" v-model="totaltransaksis" />
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>

              <td class="text-center">&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td style="font-size: 20px; font-weight: bold">Bayar</td>
              <td style="width: 150px; padding-top: 15px" class="text-center" colspan="2">
                <v-text-field
                  @keyup.enter.prevent="
                    isNumber($event)
                    getBayar()
                  "
                  v-model="bayar"
                  solo
                  clearable
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>

              <td class="text-center">&nbsp;</td>
              <td class="text-center">&nbsp;</td>
              <td style="font-size: 20px; font-weight: bold">Kembali</td>
              <td style="font-size: 20px; font-weight: bold" class="text-right" colspan="2">
                {{ kembalians }}
              </td>
            </tr>
          </tbody>
        </template> </v-simple-table
      ><br /><br />
      <v-col align="right" justify="center" cols="12" md="12">
        <v-btn @click="CreatePembelian()" type="submit" color="primary" class="me-3 mt-4"> Save </v-btn>
        <v-btn @click="deleteAllPost()" color="error" class="mt-4" type="button"> Cancel </v-btn> </v-col
      ><br />
    </v-card>
  </main>
</template>
<script>
import {
  mdiCheckBold,
  mdiCalendarMonth,
  mdiCalendarCheck,
  mdiAccountPlus,
  mdiAccountCheck,
  mdiEmailOutline,
  mdiCellphone,
  mdiCamera,
  mdiSlashForward,
  mdiArchiveSearchOutline,
  mdiDelete,
} from '@mdi/js'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/superadmin/dashboard',
        },
        {
          text: 'Transaksi Penjualan',
          disabled: false,
          href: '/superadmin/transaksipenjualan',
        },
        {
          text: 'Create Transaksi Penjualan',
          disabled: true,
        },
      ],

      icons: {
        mdiCheckBold,
        mdiCalendarMonth,
        mdiCalendarCheck,
        mdiAccountPlus,
        mdiAccountCheck,
        mdiEmailOutline,
        mdiCellphone,
        mdiCamera,
        mdiSlashForward,
        mdiArchiveSearchOutline,
        mdiDelete,
      },

      isHiddenAnggotaKoperasi: false,

      penjualan: {
        anggota: 'U',
        kode: '',
        customer: '',
      },

      data: {
        jumlah: '',
        harga_jual_ppn: '',
        ppn: '',
        sub_total_penjualan: '',
      },

      bayar: '',
      kembalian: '',
      kembalians: '',
      databarang: [],

      invoices: '',
      totaltransaksis: [],
      transaksipenjualan: [],
      customers: [],

      errors: [],

      page: 1,
      perPage: 10,
      pages: [],
      search: '',
      searchResult: [],
    }
  },
  created() {
    this.$isLoading(true)
    this.getAllBarang()
    this.getAllData()
    this.getInvoice()
    this.getTotal()
    this.getAllCustommer()
    setTimeout(() => {
      this.$isLoading(false)
    }, 2000)
  },

  methods: {
    isNumber(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
      }
    },
    resetAll() {
      this.penjualan.kode = ''
      this.bayar = ''
    },

    getAllBarang() {
      //state token
      localStorage.getItem('token')

      let uriBarangs = process.env.VUE_APP_ROOT_API + '/api/superadmin/barang-inventori-penjualan'
      return this.axios.get(uriBarangs).then(response => {
        this.databarang = response.data.barangs
      })
    },

    CreateStockOpnameModal(kode) {
      let uri = process.env.VUE_APP_ROOT_API + '/api/superadmin/penjualantemp/store'
      this.axios
        .post(uri, {
          kode: kode,
        })
        .then(response => {
          this.$isLoading(true)
          setTimeout(() => {
            this.getAllData()
            this.resetAll()
            this.$isLoading(false)
          }, 2000)
        })
        .catch(e => {
          this.$swal.fire({
            title: 'Error!',
            html: 'Produk sudah diinput !',
            icon: 'error',
            showCancelButton: false,
            showConfirmButton: false,
            timer: 2000,
          })
        })
    },

    getAllCustommer() {
      //state token
      localStorage.getItem('token')

      let url = process.env.VUE_APP_ROOT_API + '/api/superadmin/customer-index'
      return this.axios.get(url).then(response => {
        // this.customers = response.data.customer
        this.customers = response.data.anggota.map(v => {
          return v.nama_customer
        })
      })
    },

    getBayar() {
      console.log('BAYAR ' + this.bayar)
      let uri = process.env.VUE_APP_ROOT_API + '/api/superadmin/penjualantemp/bayar'
      this.axios
        .post(uri, {
          bayar: this.bayar,
        })
        .then(response => {
          this.kembalians = response.data.kembalian
          setTimeout(() => {
            this.getAllData()
            // this.resetAll()
          }, 2000)
        })
    },

    getTotal() {
      localStorage.getItem('token')
      let UriPembelian = process.env.VUE_APP_ROOT_API + '/api/superadmin/penjualantemp/total'
      return this.axios.get(UriPembelian).then(response => {
        this.totaltransaksis = response.data.totaltransaksi
      })
    },

    getInvoice() {
      localStorage.getItem('token')
      let UriPembelian = process.env.VUE_APP_ROOT_API + '/api/superadmin/penjualantemp/invoice'
      return this.axios.get(UriPembelian).then(response => {
        this.invoices = response.data.invoice
      })
    },
    getAllData() {
      localStorage.getItem('token')
      let UriPembelian = process.env.VUE_APP_ROOT_API + '/api/superadmin/penjualantemp-index'
      return this.axios.get(UriPembelian).then(response => {
        this.transaksipenjualan = response.data.transaksipenjualan
      })
    },
    Createpenjualantemp() {
      let uri = process.env.VUE_APP_ROOT_API + '/api/superadmin/penjualantemp/store'
      this.axios.post(uri, this.penjualan).then(response => {
        setTimeout(() => {
          this.getAllData()
          this.resetAll()
          this.getTotal()
        }, 2000)
      })
    },

    CreatePembelian() {
      const idBarang = this.transaksipenjualan.map(nama_barang => {
        return nama_barang.id_barang
      })

      const hargaJual = this.transaksipenjualan.map(harga_jual => {
        return harga_jual.harga_jual
      })

      const hargaJualppn = this.transaksipenjualan.map(harga_jual_ppn => {
        return harga_jual_ppn.harga_jual_ppn
      })

      const jumlah = this.transaksipenjualan.map(jumlah => {
        return jumlah.jumlah
      })

      const ppn = this.transaksipenjualan.map(ppn => {
        return ppn.ppn
      })

      const subtotal = this.transaksipenjualan.map(subtotals => {
        return subtotals.sub_total_penjualan
      })

      if (jumlah.length == 0) {
        // this.errors.push('Deskripsi harus diisi !')

        this.$swal.fire({
          title: 'Error',
          text: 'Data barang wajib diisi !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (jumlah == 0) {
        // this.errors.push('Deskripsi harus diisi !')

        this.$swal.fire({
          title: 'Error',
          text: 'Jumlah tidak boleh kosong atau nol (0) !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        console.log('ANGGOTA : ' + this.penjualan.customer)

        let uri = process.env.VUE_APP_ROOT_API + '/api/superadmin/penjualan/store'
        this.axios
          .post(uri, {
            id_barang: idBarang,
            invoice: this.invoices,
            // status_transaksi: this.penjualan.status_transaksi,
            harga_jual: hargaJual,
            harga_jual_ppn: hargaJualppn,
            jumlah: jumlah,
            ppn: ppn,
            sub_total_penjualan: subtotal,
            total: this.totaltransaksis,
            anggota: this.penjualan.anggota,
            customerkoperasi: this.penjualan.customer,
          })
          .then(response => {
            this.$swal.fire({
              title: 'Success!',
              text: 'Transaksi penjualan successfully!',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 2000,
            })
            setTimeout(() => {
              this.totaltransaksis = ''
              this.getAllData()
              this.resetAll()
            }, 2000)
          })
      }
    },

    UpdatePenjualan(id, index) {
      // if (
      //   this.transaksipenjualan[index].ppn == null ||
      //   this.transaksipenjualan[index].ppn == '' ||
      //   this.transaksipenjualan[index].ppn == 0
      // ) {
      //   console.log('KOSONG')
      //   let ppns = 0
      this.transaksipenjualan.push({ jumlah: '', harga_jual: '', harga_jual_ppn: '', id_barang: '' })
      let uriUpdatePenjualan = process.env.VUE_APP_ROOT_API + `/api/superadmin/penjualantemp/${id}/update`
      this.axios
        .post(uriUpdatePenjualan, {
          id_barang: this.transaksipenjualan[index].id_barang,
          jumlah: this.transaksipenjualan[index].jumlah,
          harga_jual: this.transaksipenjualan[index].harga_jual,
          harga_jual_ppn: this.transaksipenjualan[index].harga_jual_ppn,
          // ppn: ppns,
          datappn: this.transaksipenjualan[index].ppn,
        })
        .then(response => {})

      setTimeout(() => {
        this.getAllData()
        this.getTotal()
      }, 1000)
      // } else {
      //   let ppns = 1
      //   this.transaksipenjualan.push({ jumlah: '', harga_jual: '', harga_jual_ppn: '', id_barang: '' })
      //   let uriUpdatePenjualan = process.env.VUE_APP_ROOT_API + `/api/superadmin/penjualantemp/${id}/update`
      //   this.axios
      //     .post(uriUpdatePenjualan, {
      //       id_barang: this.transaksipenjualan[index].id_barang,
      //       jumlah: this.transaksipenjualan[index].jumlah,
      //       harga_jual: this.transaksipenjualan[index].harga_jual,
      //       harga_jual_ppn: this.transaksipenjualan[index].harga_jual_ppn,
      //       ppn: ppns,
      //       datappn: this.transaksipenjualan[index].ppn,
      //     })
      //     .then(response => {})

      //   setTimeout(() => {
      //     this.getAllData()
      //     this.getTotal()
      //   }, 1000)
      // }
    },

    deleteAllPost() {
      this.$swal
        .fire({
          title: 'Apakah anda yakin?',
          text: 'Jika anda hapus, maka data tidak akan kembali lagi.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          cancelButtonText: 'CANCEL',
        })
        .then(result => {
          if (result.value) {
            let uriDeleteAll = process.env.VUE_APP_ROOT_API + `/api/superadmin/penjualantemp/deleteall`
            this.axios
              .delete(uriDeleteAll)
              .then(response => {
                this.transaksipenjualan.splice(this.transaksipenjualan.index, 1)
                if (response.status == 200) {
                  this.$swal.fire({
                    title: 'Success!',
                    text: 'Transaksi penjualan deleted successfully!',
                    icon: 'success',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 3000,
                  })
                  setTimeout(() => {
                    this.getAllData()
                    this.totaltransaksis = ''
                    this.kembalians = ''
                  }, 1000)
                } else {
                  this.$swal.fire({
                    title: 'Error!',
                    text: 'Something wrong, can not delete transaksi penjualan!',
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 3000,
                  })
                  setTimeout(() => {
                    this.getAllData()
                    this.totaltransaksis = ''
                    this.kembalians = ''
                  }, 1000)
                }
              })
              .catch(e => {
                console.log(e)
                this.$swal.fire({
                  title: 'Error!',
                  text: 'Transaksi penjualan failed to deleted!',
                  icon: 'error',
                  showCancelButton: false,
                  showConfirmButton: false,
                  timer: 3000,
                })
                setTimeout(() => {
                  this.getAllData()
                }, 1000)
              })
          }
        })
      setTimeout(() => {
        this.getAllData()
        this.getTotal()
      }, 1000)
    },

    deletePost(id, index) {
      this.$swal
        .fire({
          title: 'Apakah anda yakin?',
          text: 'Jika anda hapus, maka data tidak akan kembali lagi.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          cancelButtonText: 'CANCEL',
        })
        .then(result => {
          if (result.value) {
            this.transaksipenjualan.push({ id_transaksi_penjualantemp: '' })
            let uriDelete = process.env.VUE_APP_ROOT_API + `/api/superadmin/penjualantemp/${id}/delete`
            this.axios
              .delete(uriDelete)
              .then(response => {
                this.transaksipenjualan.splice(this.transaksipenjualan.index, 1)
                if (response.status == 200) {
                  this.$swal.fire({
                    title: 'Success!',
                    text: 'Transaksi penjualan deleted successfully!',
                    icon: 'success',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 2000,
                  })
                  setTimeout(() => {
                    this.getAllData()
                    this.totaltransaksis = ''
                    this.kembalians = ''
                  }, 1000)
                } else {
                  this.$swal.fire({
                    title: 'Error!',
                    text: 'Something wrong, cann delete transaksi penjualan!',
                    icon: 'error',
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 2000,
                  })
                  setTimeout(() => {
                    this.getAllData()
                    this.totaltransaksis = ''
                    this.kembalians = ''
                  }, 1000)
                }
              })
              .catch(e => {
                console.log(e)
                this.$swal.fire({
                  title: 'Error!',
                  text: 'transaksi penjualan failed to deleted!',
                  icon: 'error',
                  showCancelButton: false,
                  showConfirmButton: false,
                  timer: 2000,
                })
              })

            setTimeout(() => {
              this.getAllData()
              this.getTotal()
            }, 1000)
          }
        })
    },
    doSearch() {
      const go = this.databarang.filter(item =>
        this.search
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_barang.toLowerCase().includes(v)),
      )
      this.searchResult = go
      this.pages = []
      this.setPages()
    },

    displayData() {
      let paginate

      if (this.search == '') {
        paginate = this.paginate(this.databarang)
      } else {
        paginate = this.paginate(this.searchResult)
      }

      return paginate
    },
    setPages() {
      let numberOfPages

      if (this.search == '') {
        numberOfPages = Math.ceil(this.databarang.length / this.perPage)
      } else {
        numberOfPages = Math.ceil(this.searchResult.length / this.perPage)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate(databarang) {
      const { page } = this
      const { perPage } = this
      const from = page * perPage - perPage
      const to = page * perPage

      return databarang.slice(from, to)
    },

    Pilihbarang(kode, nama_barang) {
      document.querySelector('#kode').setAttribute('value', kode)
      document.querySelector('#nama_barang').setAttribute('value', `${nama_barang}`)
      this.pembelian.kode = kode
      this.pembelian.nama_barang = nama_barang
    },
  },
  watch: {
    databarang() {
      this.pages = []
      this.setPages()
    },
  },
}
</script>
